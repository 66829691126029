<template lang="pug">
    .main-wrapper.visualizar-orcamento
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-2
                    Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                .p-col-10.ta-right
                    h1.text-header.text-secondary MedClub / Orçamentos / <b> Orçamento </b>

        Panel.mb-2(header='Orçamento')
            .ta-center.my-4(v-if='waiting')
                ProgressSpinner.waiting-spinner(strokeWidth='2')
            div(v-else)
                ConteudoVisualizar(:orcamento='orcamento')


</template>

<style scoped>
.waiting-spinner {
    width: 60px;
    height: auto;
}
</style>

<script>
import Button from "primevue/button";
import Panel from "primevue/panel";
import ProgressSpinner from "primevue/progressspinner";
import { Orcamento } from "@/middleware";
import moment from "moment";
import ConteudoVisualizar from "@/components/Orcamentos/ConteudoVisualizar";

export default {
    components: { ConteudoVisualizar, Button, Panel, ProgressSpinner },
    created() {
        if (isNaN(this.$route.params.id)) this.$router.go(-1);

        this.waiting = true;
        Orcamento.find(parseInt(this.$route.params.id)).then(response => {
            this.waiting = false;
            if ([200, 201, 204].includes(response.status)) {
                console.log("response.data", response.data);
                this.orcamento = response.data;
                this.orcamento.dt_estimada_f = moment(
                    response.data.dt_estimada
                ).format("DD/MM/YYYY");
                this.orcamento.dt_criado_f = moment(
                    response.data.dt_criado
                ).format("DD-MM-YYYY");
                if (response.data.dt_atualizado) {
                    this.orcamento.dt_atualizado_f = moment(
                        response.data.dt_atualizado
                    ).format("DD-MM-YYYY");
                }
                this.orcamento.procedimentos =
                    response.data.cd_orcamento_cirurgia_procedimento_estabelecimento;
            } else this.$router.go(-1);
        });
    },
    data() {
        return {
            waiting: false,
            orcamento: {}
        };
    }
};
</script>
